const auth = [
  'Moderator',
  'Administrator',
]

/**
 * Export config
 */
const config = {
  /**
   * All roles
   */
  roles: [
    'Member',
    ...auth
  ],

  /**
   * Reset time
   */
  resetTimeout: 600000,

  /**
   * Authorized users
   */
  authorized: auth,

  /**
   * Local storage
   */
  storage: {
    name: 'persist:root'
  },

  /**
   * Main navigation
   */
  navigation: [
    // {
    //   name: 'Dashboard',
    //   icon: 'icon-chart',
    //   auth: ['Administrator', 'School Administrator']
    // },
    {
      name: 'Accounts',
      icon: 'icon-user',
      auth: ['Administrator', 'School Administrator']
    },
    {
      auth,
      name: 'Posts',
      icon: 'icon-document',
      group:[
        {name: 'Blocked', auth},
        {name: 'Reported', auth},
      ],
    },
    {
      name: 'Schools',
      icon: 'icon-building',
      auth: ['Administrator']
    },
    {
      name: 'Support',
      icon: 'icon-headphone',
      group: [
        {name: 'Tickets'},
      ],
      auth: ['Administrator']
    },
    {
      name: 'Settings',
      icon: 'icon-cog',
      group: [
        {name: 'General'},
        {name: 'Invitation'},
        {name: 'Roles'},
      ],
      auth: ['Administrator']
    },
  ]
}
export default config