import React from 'react'
import {$generateNodesFromDOM, $generateHtmlFromNodes} from '@lexical/html'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {
  $getRoot,
  $insertNodes,
  $createTextNode,
  $createParagraphNode
}
from 'lexical'


export type EditorContentProps = {
  title?: string,
  content: string,
  onChange: Function
}


export function EditorContentPlugin({title, content, onChange}: EditorContentProps) {
  const [editor] = useLexicalComposerContext()


  React.useEffect(() => {
    editor.update(() => {
      const root = $getRoot()
      const parser = new DOMParser()
      const firstNode = $createParagraphNode()

      firstNode.append($createTextNode(title))
      if(content) {
        const nodes:any = $generateNodesFromDOM(
          editor,
          parser.parseFromString(content, 'text/html')
        )

        if(root.__cachedText === '') {
          $insertNodes((title ? [firstNode] : []).concat(nodes))
        }
      }
    })
  

    return () => {
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {

          if(onChange) {
            const title = $getRoot().getFirstChild()?.getTextContent()
            const {root} = editorState.toJSON()
  
            delete root.children[0]
            const parsed = editor.parseEditorState(JSON.stringify({
              root: {
                ...root,
                children: root.children.filter((v: any) => v)
              }
            }))
  
            const content = $generateHtmlFromNodes(editor)
            parsed.read(() => {
              const root = $getRoot()

              onChange({
                title,
                content,
                description: root.getTextContent()
              })
            })
          }
        })
      })
    }
  }, [])

  return null
}