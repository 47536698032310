import {Link} from 'react-router-dom'

/**
 * Types and Interface
 */
import {
  HttpResponse,
  DefaultComponentProps
}
from '@interface/common'

/**
 * Configuration
 */
import {app} from '@config/common'

/**
 * Helpers
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'
import {userActions} from '@features/accounts/user'

/**
 * Components
 */
import Header from './header'
import Button from '@components/button'

/**
 * Assets
 */
import './auth.scss'


/**
 * Login Screen
 */
export default pure(({store}: DefaultComponentProps) => {
  const state = useState({
    error: false,
    submit: false,
    password: '',
    confirmpassword: '',
  })

  /**
   * Reset password
   */
  function send(e: any) {
    e.preventDefault()
    /**
     * Send request
     */
    http.post('/auth/code', {
      data: {
        email: state.email,
        origin: location.origin
      },
    })
    .then(({status, result:{user, code, success}}: HttpResponse) => {
      if(status === 200) {
        if(success) {
          state.set({
            error: false,
            submit: false,
            success: `We have successfully sent you an email. Please check and follow the instruction.`,
          })
          store.dispatch(
            userActions.update({code, uid: user.uid, email: user.email})
          )
          /**
           * Remove persisted data after 10 minutes
           */
          setTimeout(() => {
            state.set({
              success: false,
              error: 'Session expired'
            })
            localStorage.removeItem(app.storage.name)
          }, app.resetTimeout)
        }
      }
      else {
        state.set({
          submit: false,
          error: 'Unauthorized request, Please use your authorized email address.'
        })
      }
    })
    .catch((e) => {
      if(e.status === 401) {
        state.set({error: 'Unauthorized Request', submit: false})
      }
    })
    state.set({submit: true})
  }


  return (
    <div id="auth">
      <Header/>
      <div className="box">
        <h1 className="strip">Request Reset Password</h1>
        <p style={{marginBottom: 20}}>Enter your email address and we will send you the instruction.</p>

        {state.error && (
          <p className="error">{state.error}</p>
        )}
        {state.success && (
          <p className="success">{state.success}</p>
        )}
        
        <form id="form" onSubmit={send}>
          {/**
           * Fields 
           */}
          <div className="field">
            <label>
              <i className="icon-email"></i>
              <span>Email Address</span>
            </label>
            <input type="email" onChange={(e) => state.set({email: e.target.value})} placeholder="Enter your email address" required/>
          </div>
          <div
            style={{
              textAlign: 'right'
            }}>
            <Link to="/" className="link">Back to Login</Link>
          </div>
          <div className="action">
            <Button
              preload={state.submit}
              style={{
                width: '10%',
                alignSelf: 'center'
              }}
              label={'Submit'}
              className={'button-3'}
            />
          </div>
        </form>
      </div>
    </div>
  )
})