import storage from 'redux-persist/lib/storage'
import {
  Action,
  ThunkAction,
  configureStore
}
from '@reduxjs/toolkit'


import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer,
}
from 'redux-persist'

/**
 * Reducers
 */
import appReducer from '@features/application'
import userReducer from '@features/accounts/user'


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['logged', 'detail']
}
const persistedReducer = persistReducer(persistConfig, userReducer)


export const store = configureStore({
  reducer: {
    app: appReducer,
    user: persistedReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)

/**
 * Get state dynamically
 */
export const getState = (name: string) => {
  const data: any = store.getState()
  
  const [reducer, state] = name.split('/')
  if(state) {
    return data[reducer][state]
  }
  return data[name]
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>