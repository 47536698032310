import {Base64} from 'js-base64'


/**
 * String manipulation
 */
const string = {
  /**
   * Parse key/value
   */
  parse(str: string) {
    var p: any = {}

    if(str.match(/\?.*/g)) {
      str = str.split('?')[1]
    }

    var d = str.split('&')
    for(let i of d) {
      var c = i.split('=')
      p[c[0]] = decodeURIComponent(c[1])
    }
    return p
  },

  /**
   * Base64 decode string
   */
  decode(text: string): string {
    return Base64.decode(text)
  },
  
  
  /**
   * Base64 encode string
   */
  encode(text: string, padding: boolean = false): string {
    return Base64.encode(text, padding).replace(/=/g,'')
  },


  /**
   * Upper case first letter
   * @param {string|undefined} string 
   * @returns string
   */
  toUCFirst(string: string | undefined) {
    if(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },


  /**
  * Replace value to variable in string
  * @param string 
  * @param data
  */
  replace(string: string, data: any) {
    let patt = string.match(/(\{[a-z_]+\})/g)
    if(patt) {
      string = string.replace(new RegExp(patt.join('|'), 'g'), (v: any) => {
        let value = data[v.match(/([a-z_]+)/g)]
        if(value) {
          return value
        }
        return ''
      })
    }
    return string
  },


  /**
   * Cut the text
   * @param text
   * @param len
   */
  ellipsis(text: string, len = 7) {
    if(text) {
      let arr = text.toString().split(' ')
      if(arr.length > len) {
        return `${arr.slice(0, len).join(' ')}...`
      }
    }
    return text
  }
}

export default string