
import React from 'react'
import {actions} from '@features/application'
import {RootState, AppDispatch} from '@redux/store'

import {
  useDispatch as dispatch,
  useSelector as selector,
}
from 'react-redux'


/**
 * Custom react hooks
 */
export function useState(initialState: any = {}) {
  const data: any = React.useRef({})

  if(typeof initialState !== 'object') {
    throw Error('Custom state hook only allow object as parameter.')
  }
  const [state, setState] = React.useState(initialState)

  data.current = Object.assign(
    data.current,
    state
  )
  return {
    ...data.current,
    async set(newState: object | Function) {
      if(typeof newState == 'function') {
        const dataState = newState(data.current)

        if(dataState) {
          if(dataState instanceof Promise) {
            setState(await dataState)
          }
          else {
            setState(dataState)
          }
        }
      }
      else {
        setState(newState)
      }
      return data.current
    },
    get(name: any = null) {
      if(name) {
        if(data.current[name]) {
          return data.current[name]
        }
      }
      else {
        return data.current
      }
    },
    data: data.current,
  }
}


/**
 * Dynamic dispatch
 * @returns {PayloadAction}
 */
export function useDispatch() {
  const fire = dispatch<AppDispatch>()

  return (data: any) => {
    if(typeof data == 'function') {
      return fire(data)
    }
    if(data.type) {
      return fire(data)
    }
    return fire(actions.set(data))
  }
}

/**
 * Get state
 */
export function useSelector(arg: any) {
  if(typeof arg === 'function') {
    return selector(arg)
  }
  return selector((state: RootState & {[key: string]: string}) => {
    const [reducer, action] = arg.split('/')

    if(action) {
      if(state[reducer]) {
        return state[reducer][action]
      }
    }
    return state.app[arg]
  })
}
