import type {RootState, AppDispatch} from '@redux/store'

import {
  TypedUseSelectorHook,
  useDispatch as dispatch,
  useSelector as selector,
}
from 'react-redux'

export const useDispatch = () => dispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = selector
