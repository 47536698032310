import React from 'react'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {datetime} from '@helpers/common'
import {TicketType, DefaultComponentProps} from '@interface/common'


/**
 * Assets
 */
import './tickets.scss'
import Spinner from '@assets/images/spinner.gif'


/**
 * Components
 */
import Ticket from '@components/ticket'
import Checkbox from '@components/checkbox'
import TableBottom from '@components/table/bottom'


/**
 * Tickets Screen
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const state = useState({
    limit: 5,
    next: null,
    status: null,
    ticket: {},
    current: 1,
    tickets: [],
    selected: [],
    fetching: true,
    openTicket: 'close',
  })

  /**
   * Get tickets
   */
  React.useEffect(() => {
    getTickets({limit: 5})
  }, [])


  /**
   * Update the table
   */
  React.useEffect(() => {
  }, [store])


  /**
   * Get current page
   */
  function page(name: string, page: string|number, last?: string|number) {
    if(page) {
      getTickets({last, [name]: page})
    }
  }

  
  /**
   * Is checked?
   */
  function isChecked() {
    if(state.selected.length === 0) {
      return false
    }
    return state.selected.length === state.tickets.length ? true : false
  }


  /**
   * Get tickets
   */
  function getTickets(query:any = {}) {
    http.get('/support/tickets', {
      query: {
        limit: state.limit,
        status: state.status,
        ...query,
      }
    })
    .then(({status, result}) => {
      if(status === 200) {
        state.set({
          fetching: false,
          total: result.total,
          tickets: result.tickets ?? [],
        })
      }
    })
    .catch(e => console.log(e))
  }


  return (
    <React.Fragment>
      <header>
        <div className="left inline">
          <h3 className="margin-0">Tickets</h3>
        </div>
        <div className="right inline">
          <div className="filter">
            <label>Status</label>
            <select
              onChange={(e) => {
                getTickets({status: e.target.value})
              }}>
              <option value="">All</option>
              <option value="open">Open</option>
              <option value="pending">Pending</option>
              <option value="reopened">Reopened</option>
              <option value="closed">Closed</option>
              <option value="solved">Solved</option>
            </select>
          </div>
        </div>
      </header>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  space={30}
                  onClick={(checked: boolean) => {
                    state.set({
                      selected: state.tickets.reduce(
                        (items: any[], item: any) => {
                          if(checked) {
                            items.push({
                              tid: item.tid,
                            })
                          }
                          else {
                            return items.filter((v: any) => v.tid !== item.tid)
                          }
                          return items
                        },
                      [])
                    })
                  }}
                  checked={isChecked()}
                />
              </th>
              <th>User</th>
              <th>Issue</th>
              <th>Assignee</th>
              <th>Status</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.fetching ? (
              <tr>
                <td colSpan={6} style={{textAlign: 'center'}}>
                  <img alt={'Spinner'} src={Spinner} style={{width: 32, padding: 20}}/>
                </td>
              </tr>
            ):(
              <>
                {state.tickets.length > 0 ? (
                  <>
                    {state.tickets.map((item: TicketType, key: number) => {
                      let date = datetime(item.created)
                      return (
                        <tr key={key}>
                          <td style={{width: 20}}>
                            <Checkbox
                              space={30}
                              onClick={(checked: boolean) => {
                                state.set(({selected}: any) => {
                                  if(checked) {
                                    selected.push(
                                      {tid: item.tid}
                                    )
                                  }
                                  else {
                                    selected = selected.filter((v: any) => v.tid !== item.tid)
                                  }
                                  return {selected}
                                })
                                
                              }}
                              checked={(
                                state.selected.filter((v: any) => v.tid === item.tid).length > 0
                              )}
                            />  
                          </td>
                          <td>{item.user?.name}</td>
                          <td>{string.ellipsis(item.issue, 5)}</td>
                          <td>{item.assignee?.name ?? '-'}</td>
                          <td>
                            {string.toUCFirst(item.status)}
                          </td>

                          <td style={{width: 250}}>{date.getDate()}</td>
                          <td style={{width: 100}}>
                            <div className="flex-end">
                              <span
                                className="button-1"
                                onClick={() => {
                                  state.set({openTicket: 'open', ticket: item})
                                }}>
                                <span>View Ticket</span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ):(
                  <tr>
                    <td colSpan={7} style={{textAlign: 'center'}}>
                      <span>No Results</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <TableBottom
        entries={{
          onChange(e) {
            getTickets({limit: e.target.value})
          }
        }}
        pagination={{
          limit: state.limit,
          total: state.total,
          onClick(selected) {
            page('page', selected, state.tickets.at(-1)?.created)
          },
          onPrev() {
            page('prev', state.tickets[0]?.created)
          },
          onNext() {
            page('next', state.tickets.at(-1)?.created)
          },
        }}
      />

      {['open','hide'].includes(state.openTicket) && (
        <Ticket
          open={(status: string) => {
            state.set({openTicket: status})
          }}
          status={state.openTicket}
          ticket={state.ticket}
        />
      )}
    </React.Fragment>
  )
})