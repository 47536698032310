import React from 'react'
import {
  Outlet
}
from 'react-router-dom'


import Panel from '@layout/panel'
import {selectUser} from '@features/accounts/user'
import {useSelector} from '@redux/hooks'


export default function Container() {
  var user: any = useSelector(selectUser)
  
  if(user && user.uid) {
    return (
      <React.Fragment>
        <Panel user={user}/>
        <div id="content">
          <Outlet/>
        </div>
      </React.Fragment>
    )
  }
  else {
    window.location.href = '/'
  }
  return null
}