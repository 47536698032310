/*
eslint-disable import/no-anonymous-default-export
*/


/**
 * Check if key exist in an object
 * 
 * @param {array | string} needle List of keys you want to check
 * @param {object} haystack The object to check
 */
export function has(needle:string|string[], haystack:object) {
  if(!needle || !haystack) {
    return
  }

  if(typeof haystack !== 'object') {
    throw TypeError('Second argument must be type of object.')
  }

  if(typeof needle !== 'string' && needle.length === undefined) {
    throw TypeError('First argument must be type of array or string.')
  }


  var list = Object.keys(haystack)
  if(Array.isArray(needle)) {
    if(needle.filter((val) => list.includes(val)).length === needle.length) {
      return true
    }
  }

  if(typeof needle === 'string') {
    return list.includes(needle)
  }
}

/**
 * Default
 */
export default {has}