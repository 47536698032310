import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'

/**
 * Types and Interface
 */
import {
  HttpOption,
  HttpInterface,
}
from '@interface/http'


/**
 * Http Request
 */
const methods: string[] = [
  'get',
  'put',
  'post',
  'delete'
]


/**
 * Form data
 */
function formData(data: any) {
  const form = new FormData()

  for(let i in data) {
    const item = data[i]

    if(data[i] instanceof Object && !(item.size && item.type)) {
      form.append(i, JSON.stringify(item))
    }
    else {
      form.append(i, item)
    }
  }
  return form
}

/**
 * Axios Instance
 */
function instance(baseURL:string, method: string) {
  const instance: AxiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    }
  })
  switch(method) {
    case 'get':
      return instance.get
    case 'put':
      return instance.put
    case 'post':
      return instance.post
    case 'delete':
      return instance.delete
    default:
      return instance
  }
}

/**
 * Get http methods
 */
export const HttpRequest = function(baseURL: string, user:any) {
  const config: AxiosRequestConfig = {}
  const HttpRequest: HttpInterface = {}

  /**
   * HTTP Request
   */
  function request(path: string, method: string, opt?: HttpOption) {
    var req = instance(baseURL, method)

    if(opt?.query) {
      config.params = opt.query
    }
    /**
     * Default auth
     */
    if(user && !opt?.headers?.hasOwnProperty('Authorization')) {
      config.headers = {
        'Authorization': `Bearer ${user.token}`,
        ...opt?.headers
      }
    }
    else {
      config.headers = opt?.headers
    }

    if(['put','post','patch'].includes(method)) {
      var data: any = {}

      if(opt?.data) {
        data = opt.data
      }
      if(opt?.headers) {
        if(opt.headers['Content-Type'] && opt.headers['Content-Type'].match(/multipart/g)) {
          data = formData(data)
        }
      }
      return req(path, data, config)
    }

    return req(path, config)
  }


  for(let method of methods) {
    HttpRequest[method] = (path: string, opt?: HttpOption) => {
      const res = request(path, method, opt)

      return new Promise((resolve, reject) => {
        if(res) {
          return res.then(
            (r) => resolve(r.data)
          )
          .catch((e) => {
            if(e.response) {
              reject(e.response.data)
            }
            else {
              reject(e.message)
            }
          })
        }
      })
    }
  }
  return HttpRequest
}