import React from 'react'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'
import {DefaultComponentProps} from '@interface/common'

/**
 * Components
 */
import Button from '@components/button'

/**
 * Template Settings Screen
 */
export default pure(({user}: DefaultComponentProps) => {
  const state = useState({
    error: false,
    analyzer: {
      toxicity: 0
    },
    download: {
      ios: '',
      title: '',
      content: '',
      android: '',
      redirect: '',
    }
  })

  React.useEffect(() => {
    http.get('/settings', {
      query: {
        fields: [
          'analyzer',
          'download',
        ]
      }
    })
    .then(({status, result}:any) => {
      if(status === 200 && result) {
        state.set(result)
      }
    })
    .catch(console.log)
  }, [])

  
  /**
   * Save settings
   */
  function save() {
    state.set({
      saving: true
    })
    
    http.put('/settings', {
      data: {
        analyzer: state.analyzer,
        download: state.download
      }
    })
    .then(({status, result}:any) => {
      if(status === 200) {
        if(result) {
          state.set({saving: false, ...result})
        }
      }
    })
    .catch(console.log)
  }


  /**
   * Handle change
   */
  function change(name: string, data: object) {
    state.set((state: any) => {
      return {
        [name]: {...state[name], ...data}
      }
    })
  }


  return (
    <div className="box">
      <header className="line">
        <h3>General Settings</h3>
      </header>
      
      <div
        style={{
          width: '50%'
        }}>
        <fieldset className="fields">
          <div style={{marginBottom: 40}}>
            <h4 style={{marginBottom: 5}}>Content Analyzer</h4>
            <p>
              This field is the maximum toxicity score allowed of a post to be publish, the higher the score the more likely to be toxic.
            </p>
          </div>
          <div className="field">
            <label>Accepted Toxicity Score</label>
            <input
              type="text"
              placeholder="e.g 0.5"
              value={state.analyzer.toxicity}
              onChange={(e) => {
                change('download', {toxicity: e.target.value})
              }}
            />
          </div>
        </fieldset>
        <fieldset className="fields">
          <div style={{marginBottom: 40}}>
            <h4 style={{marginBottom: 5}}>Invite and Download</h4>
            <p>The information below will be use for sharing the app to the other platform to invite someone to download the app.</p>
          </div>
          <div className="field">
            <label>Title</label>
            <input
              type="text"
              value={state.download.title}
              onChange={(e) => {
                change('download', {title: e.target.value})
              }}
            />
          </div>
          <div className="field">
            <label>Message</label>
            <textarea
              value={state.download.content}
              onChange={(e) => {
                change('download', {content: e.target.value})
              }}
            />
          </div>
          <div className="field">
            <label>App Store</label>
            <input
              type="text"
              value={state.download.ios}
              onChange={(e) => {
                change('download', {ios: e.target.value})
              }}
            />
          </div>
          <div className="field">
            <label>Google Play</label>
            <input
              type="text"
              value={state.download.android}
              onChange={(e) => {
                change('download', {android: e.target.value})
              }}
            />
          </div>
          <div className="field">
            <label>Redirect URL</label>
            <input
              type="text"
              value={state.download.redirect}
              onChange={(e) => {
                change('download', {redirect: e.target.value})
              }}
            />
          </div>
        </fieldset>
      </div>
      <Button
        label={'Save'}
        style={{
          marginTop: 30
        }}
        preload={state.saving}
        onClick={save}
        className={'button-2'}
      />
    </div>
  )
})