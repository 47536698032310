const DEV_ENDPOINT = 'http://localhost:8080'
// const PRO_ENDPOINT = 'https://bhapi-a7b56.uc.r.appspot.com'
// const DEV_ENDPOINT = 'https://dev-dot-bhapi-a7b56.uc.r.appspot.com'
const PRO_ENDPOINT = 'https://dev-dot-bhapi-a7b56.uc.r.appspot.com'


/**
 * Endpoint and origin
 */
const env: any = {}

if(['127.0.0.1','localhost'].includes(window.location.hostname)) {
  env.ORIGIN = DEV_ENDPOINT
  env.ENDPOINT = `${env.ORIGIN}/office/v1`
}
else {
  env.ORIGIN = PRO_ENDPOINT
  env.ENDPOINT = `${env.ORIGIN}/office/v1`
}

export default env