import React from 'react'
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {PersistGate} from 'redux-persist/integration/react'

import Router from '@react/router'
import {store, persistor} from '@redux/store'

/**
 * Document
 */
const root = createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// Log results (for example: reportWebVitals(console.log))
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
