import {createSlice} from '@reduxjs/toolkit'


/**
 * Dynamic properties
 */
const initialState: {[key: string]: any} = {}

/**
 * Reducers
 */
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    set(state, action) {
      for(var key in action.payload) {
        state[key] = action.payload[key]
      }
    },
  },
})
export const actions = appSlice.actions
export default appSlice.reducer