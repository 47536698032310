/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'
import {DefaultComponentProps} from '@interface/common'

/**
 * Components
 */
import Button from '@components/button'
import Editor from '@components/editor'

/**
 * Template Settings Screen
 */

export default pure(({user}: DefaultComponentProps) => {
  const state = useState({
    error: false,
    permission: {
      title: '',
      message: [
        {
          type: 'paragraph',
          children: [
            {text: ''},
          ],
        }
      ]
    }
  })

  /**
   * Handle change
   */
  function change(type: string, name: string) {
    return (e: any) => {
      state.set((state: any) => {
        return {
          [type]: {...state[type], [name]: e.target ? e.target.value : e}
        }
      })
    }
  }

  
  /**
   * Edit user
   */
  function edit(e:any) {
    e.preventDefault()
    /**
     * Send request
     */
    http.put(`/settings`, {
      data: {}
    })
    .then(({status, result}:any) => {
      if(status === 200) {
        if(result) {
          state.set({submit: false, ...result})
        }
      }
    })
    .catch((e: any) => {

    })
    state.set({submit: true})
  }

  return (
    <div className="box">
      <header className="line">
        <h3>Email Invitation</h3>
      </header>
      <div style={{width: '50%'}}>
        <div className="fields">
          <h4>School Invitation</h4>
          <p>Invite schools to register their school to bhapi social media.</p>
          <div className="field">
            <label>Title</label>
            <input type="text" onChange={(e) => state.set({title: e.target.value})}/>
          </div>
          <div className="field">
            <label>Content</label>
            <div className="editor">
              <Editor
                title={state.title}
                content={state.content}
                onChange={({title, content, description}: {title: string, content: string, description: string}) => {
                  // if(state.description) {
                  //   description = state.description
                  // }
                  // else {
                  //   description = sliceWords(description)
                  // }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Button label={'Save'} className={'button-2'} style={{marginTop: 30}}/>
    </div>
  )
})