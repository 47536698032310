import React from 'react'
/**
 * Helpers
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'
import {
  HttpResponse,
  PostInterface,
  DefaultComponentProps
}
from '@interface/common'

/**
 * Components
 */
import Button from '@components/button'

/**
 * Images
 */
import Close from '@assets/images/close.png'

/**
 * Export a certain modal
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const state = useState({
    post: {
      user: {}
    },
    loading: true
  })

  /**
   * Get post
   */
  React.useEffect(() => {
    const data = modal.data()

    http.get(`/posts/${data.pid}`)

    .then(({status, result}: HttpResponse) => {
      if(status === 200) {
        state.set({post: result, loading: false})
      }
    })
    .catch((e: any) => {
      state.set({error: e.message})
    })
  }, [])


  
  /**
   * Approve or Block post
   */
  function update(action: string, post: PostInterface) {
    /**
     * Send request
     */
    http.put(`/posts/${post.pid}`, {
      data: {
        post: {
          pid: post.pid,
          uid: post.user.uid,
        },
        action: action,
        moderator: user.uid
      }
    })
    .then(({status}: HttpResponse) => {
      if(status === 200) {
        store.dispatch({updated: {action, post}, modal: null})
      }
    })
    .catch((e: any) => {
      state.set({error: e.data.result.error})
    })
  }


  return (
    <div
      id="review"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <div className="head">
        <h2>Review Post</h2>
        <span
          className="close"
          onClick={modal.close}>
          <img alt="Close" src={Close}/>
        </span>
      </div>
      {state.loading ? (
        <div
          style={{
            padding: 40,
            marginBottom: 30,
            textAlign: 'center',
            border: '1px solid #ddd',
          }}>
          {/* <img src={Spinner} width={32}/> */}
        </div>
      ):(
        <div
          style={{
            lineHeight: 0,
            borderRadius: 5,
            marginBottom: 30,
            border: '1px solid #ddd',
          }}>
          <div className="avatar">
            <span className="image">
              <img src={state.post.user.photo} alt="User avatar"/>
            </span>
            <span className="name">
              <strong>{state.post.user.firstname} {state.post.user.lastname}</strong>
              <span>@{state.post.user.username}</span>
            </span>
          </div>
          {state.post.text && (
            <div style={{lineHeight: 1.5, padding: '0 20px 15px'}}>{state.post.text}</div>
          )}
          <img
            width={'100%'}
            alt="Post media"
            src={state.post.media && state.post.media.path}
          />
        </div>
      )}
      <Button
        style={{
          width: '100%'
        }}
        label={'Block'}
        onClick={() => {
          update('block', state.post)
        }}
        className={'button-1'}
      />
      <Button
        style={{
          width: '100%',
          marginTop: 20
        }}
        label={'Approve'}
        onClick={() => {
          update('approve', state.post)
        }}
        className={'button-3'}
      />
    </div>
  )
})
