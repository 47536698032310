/**
 * Components
 */
import Pagination from '@components/pagination'


type TableBottomProps = {
  entries: {
    onChange: (e:any) => void
  },
  pagination: {
    total: number
    limit: number
    onPrev?: (page:string|number) => void
    onNext?: (page:string|number) => void
    onClick?: (page:string|number, current:string|number) => void
  }
}


export default function TableBottom({pagination}: TableBottomProps) {
  return (
    <footer className="table-bottom inline">
      <div className="select">
        <select
          onChange={(e) => {
            
          }}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
        </select>
        <span>Entries</span>
      </div>
      <div className="pagination">
        <Pagination
          limit={pagination.limit}
          total={pagination.total}
          onPrev={pagination.onPrev}
          onNext={pagination.onNext}
          onClick={pagination.onClick}
        />
      </div>
    </footer>
  )
}