import string from '@helpers/string'
import {http} from '@helpers/common'

/**
 * Types and Interface
 */
import {
  HttpResponse,
}
from '@interface/common'


export type UserLogin = {
  data: {
    email: string,
    password: string
  },
  query?: object,
}


/**
 * Login user
 */
export async function userLoginAPI({data, query}: UserLogin): Promise<HttpResponse> {
  return http.post('/auth/login', {
    query,
    headers: {
      Authorization: `Basic ${string.encode(`${data.email}:${data.password}`)}`
    },
  })
}


/**
 * Get accounts
 * @param data 
 * @returns {promise}
 */
export async function usersAPI(data: object): Promise<any> {
  try {
    const response: HttpResponse = await http.post(`/accounts`, {
      data,
    })
    if(response.status === 200) {
      return response.result
    }
  }
  catch(e) {
    return e
  }
}
