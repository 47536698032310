const events: any = {}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  /**
   * Add event
   */
  on(name: string, cb: Function) {
    events[name] = {type: 'on', callback: cb}
  },

  /**
   * Execute the event
   */
  emit(name: string, ...data: any) {
    if(events[name]) {
      events[name].callback.call({}, ...data)
    }
  },

  /**
   * Remove event
   */
  remove: (name: string) => delete events[name]
}