/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {DefaultComponentProps} from '@interface/common'

/**
 * Components
 */
import ProgressBar from '@components/progress'


/**
 * Schools Screen
 */
export default pure(({data}: {data:any} & DefaultComponentProps) => {
  return (
    <div
      className="alert"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <h3>{data.title}</h3>
      <p>{data.message}</p>
      <ProgressBar/>
    </div>
  )
})