import React from 'react'

/**
 * Types and Interface
 */
import {
  ScoreType,
  PostsState,
  HttpResponse,
  PostInterface,
  DefaultComponentProps,
}
from '@interface/common'

/**
 * Helpers
 */
import {pure} from '@react/derive'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {
  http,
  datetime,
}
from '@helpers/common'

/**
 * Components
 */
import Checkbox from '@components/checkbox'
import TableBottom from '@components/table/bottom'


/**
 * Images
 */
import './posts.scss'
import Spinner from '@assets/images/spinner.gif'



/**
 * Posts Screen
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const state = useState({
    post: {},
    posts: [],
    selected: [],
    start: 0,
    limit: 20,
    type: null,
    error: false,
    review: false,
    block: false,
    status: null,
    approve: false,
    loading: true,
    fetching: true,
    moderator: null,
  })

  /**
   * Get users
   */
  React.useEffect(() => {
    posts()
  }, [])


  /**
   * Update posts
   */
  React.useEffect(() => {
    const updated = store.get('updated')

    if(updated) {
      state.set(({posts}: PostsState) => {
        if(updated.action === 'block') {
          posts = posts?.map((item: PostInterface) => {
            item = {...item}
            if(item.pid === updated.post.pid) {
              item.status = 'blocked'
            }
            return item
          })
        }
        if(updated.action === 'approve') {
          posts = posts?.filter((v: PostInterface) => v.pid !== updated.post.pid && v)
        }
        return {posts}
      })
    }
  }, [store])


  
  /**
   * Is checked?
   */
  function isChecked() {
    if(state.selected.length === 0) {
      return false
    }
    return state.selected.length === state.posts.length ? true : false
  }


  /**
   * Get posts
   * @param {Object} state
   */
  function posts() {
    /**
     * Send request
     */
    http.get(`/posts`, {
      query: {
        type: state.type,
        limit: state.limit,
        start: state.start,
        status: state.status,
        moderator: state.moderator,
        user: {
          uid: user.uid,
          role: user.organization.role,
        }
      },
    })
    .then(({result}: HttpResponse) => {
      if(result) {
        state.set({posts: result, error: false, fetching: false})
      }
      else {
        state.set({posts: [], fetching: false})
      }
    })
    .catch((e: any) => {
      console.log(e)
    })
    state.set({fetching: true})
  }


  return (
    <div className="inner">
      <header>
        <div className="left">
          <h3 className="margin-0">Blocked</h3>
        </div>
        <div className="right filter">
          <span className="select">
            <label>Type</label>
            <select onChange={(e) => state.set({type: e.target.value})}>
              <option value="">All</option>
              <option value="text">Text</option>
              <option value="share">Share</option>
              <option value="link">Link</option>
              <option value="media">Media</option>
            </select>
          </span>
          <span className="select">
            <label>Status</label>
            <select onChange={(e) => state.set({status: e.target.value})}>
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="blocked">Blocked</option>
            </select>
          </span>
          {user.organization.role === 'Administrator' && (
            <span className="select">
              <label>Moderator</label>
              <select onChange={(e) => state.set({moderator: e.target.value})}>
                <option value="">All</option>
                <option value="">Name 1</option>
                <option value="">Name 2</option>
              </select>
            </span>
          )}
          <span onClick={() => posts()} className="button-3">Filter</span>
        </div>
      </header>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  space={30}
                  onClick={(checked: boolean) => {
                    state.set({
                      selected: state.posts.reduce(
                        (items: [], item: any) => {
                          if(checked) {
                            items.push(item.pid as never)
                          }
                          else {
                            return items.filter((v: string) => v !== item.pid)
                          }
                          return items
                        },
                      [])
                    })
                  }}
                  checked={isChecked()}
                />
              </th>
              <th>Caption</th>
              <th>Score</th>
              <th>Type</th>
              <th>Status</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.fetching ? (
              <tr>
                <td colSpan={5} style={{textAlign: 'center'}}>
                  <img alt={'Spinner'} src={Spinner} style={{width: 32, padding: 20}}/>
                </td>
              </tr>
            ):(
              <>
                {state.posts.length > 0 ? (
                  <>
                    {state.posts.map((item: PostInterface, key: number) => {
                      const date = datetime(item.created)

                      return (
                        <tr key={key}>
                          <td style={{width: 20}}>
                            <Checkbox
                              space={30}
                              onClick={(checked: boolean) => {
                                state.set(({selected}: any) => {
                                  if(checked) {
                                    selected.push(item.pid)
                                  }
                                  else {
                                    selected = selected.filter((v: string) => v !== item.pid)
                                  }
                                  return {selected}
                                })
                                
                              }}
                              checked={state.selected.includes(item.pid)}
                            />  
                          </td>
                          <td style={{maxWidth: 300}}>
                            {item.text ? string.ellipsis(item.text, 15) : (
                              <span style={{fontStyle: 'italic', color: '#bbb'}}>n/a</span>
                            )}
                          </td>
                          <td>
                            {item.score && (({text, image, video}: ScoreType) => {
                              if(item.type === 'media') {
                                if(image) {
                                  return image.value && image.value.toFixed(2)
                                }
                                if(video) {
                                  return video.value && video.value.toFixed(2)
                                }
                              } else {
                                if(text) {
                                  return text.value && text.value.toFixed(2)
                                }
                              }
                              return <i style={{color: '#bbb'}}>n/a</i>
                            })
                            (item.score)}
                          </td>
                          <td>{string.toUCFirst(item.type)}</td>
                          <td>{string.toUCFirst(item.status)}</td>
                          <td style={{width: 250}}>{date.getDate()}</td>
                          <td style={{width: 100}}>
                            <span
                              className="button-1"
                              onClick={() => {
                                modal.open({name: 'review', data: item})
                              }}>
                              <span>Review</span>
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ):(
                  <tr>
                    <td colSpan={5} style={{textAlign: 'center'}}>
                      <span>No Results</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <TableBottom
        entries={{
          onChange(e) {}
        }}
        pagination={{
          limit: state.limit,
          total: state.total,
          onClick(selected) {
            
          },
          onPrev() {
            
          },
          onNext() {
            
          },
        }}
      />
    </div>
  )
})