import {RootState} from '@redux/store'

import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
}
from '@reduxjs/toolkit'

/**
 * Configuration
 */
import config from '@config/common'

/**
 * User API
 */
import {HttpResponse} from '@interface/http'
import {userLoginAPI} from '@redux/features/accounts/api'


type initialStateType = {
  status: {
    failed?: string
    loading?: string
    success?: string
  },
  detail: null | object,
}

const initialState: initialStateType = {
  detail: {},
  status: {
    loading: 'loading'
  }
}

/**
 * Async Request
 */
export const userLogin = createAsyncThunk('user/userLogin', userLoginAPI)

/**
 * Reducers
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      state.detail = null
    },
    update(state, {payload}) {
      state.detail = {...state.detail, ...payload}
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(userLogin.pending, (state) => {
      state.status.loading = 'loading'
    })

    .addCase(userLogin.fulfilled, (state, {payload}: PayloadAction<HttpResponse>) => {
      if(!payload) {
        return
      }

      if(payload.status === 200) {
        const user = payload.result

        if(user) {
          state.detail = user

          if(config.app.authorized.includes(user.organization.role)) {
            window.location.href = '/office/accounts'
            state.status.success = 'success'
          }
          else {
            state.status.failed = 'Unauthorized login'
          }
        }
      }
    })
    
    .addCase(userLogin.rejected, (state, {error}: any) => {
      if(error.message === 'Unauthorized') {
        state.status.failed = 'Invalid Email Address/Password'
      }
    })
  },
})

export const userActions = userSlice.actions
export const selectUser = (state: RootState) => state.user.detail


export default userSlice.reducer