/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {DefaultComponentProps} from '@interface/common'


/**
 * Components
 */
import Button from '@components/button'


/**
 * Schools Screen
 */
export default pure(({store, modal, data}: {data:any} & DefaultComponentProps) => {
  return (
    <div
      className="alert"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <h2>{data.title}</h2>
      <p>{data.message}</p>
      
      <div className="inline">
        <Button
          label={'Cancel'}
          style={{
            width: '100%'
          }}
          onClick={modal.close}
          className={'button-1'}
        />
        <Button
          label={data.button}
          style={{
            width: '100%'
          }}
          onClick={() => {
            store.dispatch({confirmed: data.key, modal: null})
          }}
          className={'button-3'}
        />
      </div>
    </div>
  )
})