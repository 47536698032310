/**
 * Images
 */
import Spinner from '@assets/images/spinner-white.gif'

/**
 * Types
 */
type ButtonProps = {
  label: string
  style?: object
  preload?: boolean
  className?: string
  onClick?: (e: any) => void
}

/**
 * Export Button
 */
export default function Button(props:ButtonProps) {
  return (
    <button
      style={{
        minWidth: 80,
        ...props.style
      }}
      onClick={props.onClick}
      className={props.className}>
      {props.preload ? (
        <img alt={'Spinner'} src={Spinner} width={16} style={{lineHeight: 0}}/>
      ):(
        <span style={{lineHeight: 1.5}}>{props.label}</span>
      )}
    </button>
  )
}