import endpoint from '@config/endpoint'
import application from '@config/application'


export const env = endpoint
export const app = application
/**
 * Export config
 */
const config = {
  env: endpoint,
  app: application
}
export default config