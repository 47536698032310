import {Link} from 'react-router-dom'

/**
 * Types and Interface
 */
import {DefaultComponentProps} from '@interface/common'

/**
 * Helpers
 */
import {pure} from '@react/derive'
import {useState} from '@react/hooks'
import {userLogin} from '@redux/features/accounts/user'

/**
 * Components
 */
import Header from './header'
import Button from '@components/button'

/**
 * Assets
 */
import './auth.scss'


/**
 * Login Screen
 */
export default pure(({store}: DefaultComponentProps) => {

  const state = useState({
    email: '',
    password: '',
    submit: false,
    preload: false
  })

  /**
   * Login user with username and password
   */
  function login(e: any) {
    e.preventDefault()
    /**
     * Send login request
     */
    store.dispatch(
      userLogin({
        data: {
          email: state.email,
          password: state.password
        }
      })
    )
    state.set({submit: true})
  }

  return (
    <div id="auth">
      <Header/>

      <div className="box">
        <h1>Login your account</h1>
        
        <form id="form" onSubmit={login}>
          {(store.status.failed) && (
            <p className="error">{store.status.failed}</p>
          )}
          {/**
           * Fields 
           */}
          <div className="inline">
            <div className="field">
              <label>
                <i className="icon-email"></i>
                <span>Email Address</span>
              </label>
              <input
                required
                type="email"
                value={state.email}
                onChange={(e) => state.set({email: e.target.value})}
                placeholder="Email Address"
              />
            </div>
            <div className="field">
              <label>
                <i className="icon-lock"></i>
                <span>Password</span>
              </label>
              <input type="password" onChange={(e) => state.set({password: e.target.value})} placeholder="Password" required/>
            </div>
          </div>
          <div
            style={{
              textAlign: 'right'
            }}>
            <Link to="/auth/forgot" className="link">Forgot password?</Link>
          </div>
          <div className="action">
            <Button
              preload={state.submit}
              style={{
                width: '10%',
                alignSelf: 'center'
              }}
              label={'Login'}
              className={'button-3'}
            />
          </div>
        </form>
      </div>
    </div>
  )
})