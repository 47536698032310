/**
 * Helpers
 */
import {http} from '@helpers/common'


/**
 * Search box
 */
export default function Search({args, type, onChange}: {args?: object, type: string, onChange: Function}) {
  return (
    <input
      type="text"
      onChange={(e) => {
        http.get('/search', {
          query: {
            ref: args,
            type,
            keywords: e.target.value
          }
        })
        .then(({status, result}) => {
          if(status === 200) {
            onChange(result ?? [])
          }
        })
        .catch((e) => console.log(e))
      }}
    />
  )
}