import React from 'react'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {useState} from '@react/hooks'
import {DefaultComponentProps} from '@interface/common'


/**
 * Schools Screen
 */
export default pure(({store}: {data:any} & DefaultComponentProps) => {
  var state = useState({
    count: 0
  })


  React.useEffect(() => {
    var i = Math.floor(Math.random() * 50)

    const interval = setInterval(() => {
      if(i > 100) {
        store.dispatch({
          modal: null
        })
        clearInterval(interval)
      }
      else {
        state.set({count: i})
      }
      i++
    }, 800)
  }, [])


  return (
    <div className="progress">
      <div className="percent" style={{width: `${state.count}%`}}/>
    </div>
  )
})