import React from 'react'
import {Link} from 'react-router-dom'

/**
 * Types and Interface
 */
import {
  HttpResponse,
  DefaultComponentProps
}
from '@interface/common'

/**
 * Configuration
 */
import {app} from '@config/common'

/**
 * Helpers
 */
import {pure} from '@react/derive'
import {useState} from '@react/hooks'
import {http, validate} from '@helpers/common'

/**
 * Components
 */
import Header from './header'
import Button from '@components/button'

/**
 * Assets
 */
import './auth.scss'


/**
 * Login Screen
 */
export default pure(({user}: DefaultComponentProps) => {
  const state = useState({
    error: false,
    submit: false,
    password: '',
    confirmpassword: '',
  })

  
  /**
   * Redirect if no code
   */
  React.useEffect(() => {
    if(!user.code) {
      location.href = '/auth/forgot'
    }

    /**
     * Remove persisted data after 10 minutes
     */
    setTimeout(() => {
      state.set({
        error: 'Session expired'
      })
      localStorage.removeItem(app.storage.name)
    }, app.resetTimeout)
  }, [])


  /**
   * Reset password
   */
  function reset(e:any) {
    e.preventDefault()

    const validated = validate.passwordStrength(state.password)

    if(!user.code) {
      return
    }
    /**
     * Empty fields
     */
    if(state.password === '' || state.retyped === '') {
      return state.set({
        preload: false,
        error: 'Please fill the empty field.'
      })
    }
    /**
     * If password has enough strength
     */
    if(validated === true) {
      if(state.password === state.retyped) {
        /**
         * Send request
         */
         http.post('/auth/reset', {
          data: {
            ref: location.href,
            code: user.code,
            user: user.uid,
            email: user.email,
            password: state.password,
          },
        })
        .then(({status, result}: HttpResponse) => {
          if(status === 200) {
            if(result.success) {
              location.href = '/'
            }
            localStorage.removeItem(app.storage.name)
          }
          else {
            state.set({error: 'Unable to change your password.', submit: false})
          }
        })
        .catch((e:any) => {
          state.set({error: e.toString(), submit: false})
        })
      }
      else {
        return state.set({
          submit: false,
          error: 'Password doesn\'t matched.'
        })
      }
    }
    else {
      return state.set({
        submit: false,
        error: validated,
      })
    }
    state.set({submit: true}) 
  }


  return (
    <div id="auth">
      <Header/>
      <div className="box">
        <h1 className="strip">Create New Password</h1>
        <p style={{marginBottom: 20}}>Enter at least 10 characters for your new password.</p>

        {state.error && (
          <p className="error">{state.error}</p>
        )}
        {state.success && (
          <p className="success">{state.success}</p>
        )}
        
        <form id="form" onSubmit={reset}>
          {/**
           * Fields 
           */}
          <div className="field" style={{marginBottom: 10}}>
            <label>New Password</label>
            <input type="password" onChange={(e) => state.set({password: e.target.value})} required/>
          </div>
          <div className="field">
            <label>Retype New Password</label>
            <input type="password" onChange={(e) => state.set({retyped: e.target.value})} required/>
          </div>
          <div
            style={{
              textAlign: 'right'
            }}>
            <Link to="/" className="link">Back to Login</Link>
          </div>
          <div className="action">
            <Button
              preload={state.submit}
              style={{
                width: '10%',
                alignSelf: 'center'
              }}
              label={'Submit'}
              className={'button-3'}
            />
          </div>
        </form>
      </div>
    </div>
  )
})