import React from 'react'
import {Link, useLocation} from 'react-router-dom'

/**
 * Types and Interface
 */
import {
  DefaultComponentProps
}
from '@interface/common'

/**
 * Helpers
 */
import {pure} from '@react/derive'

/**
 * App configuration
 */
import app from '@config/application'

/**
 * Redux hooks
 */
import {useDispatch} from '@redux/hooks'
import {userActions} from '@features/accounts/user'

/**
 * Images
 */
import Logo from '@assets/images/logo-orange.png'


type ItemTypes = {
  icon?: string,
  name: string,
  auth?: string[],
  group?: ItemTypes[],
}


export default pure(({user}: DefaultComponentProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  
  const pathname = location.pathname.split('/').reverse()

  /**
   * Active page
   */
  function isActive(name: string) {
    if(pathname[0] === name.replace(/\s/, '-').toLowerCase()) {
      return 'active'
    }
  }

  /**
   * Create a path
   */
  function makepath(p: any) {
    return ['office'].concat(p).join('/').replace(/\s/, '-').toLowerCase()
  }

  /**
   * Restrict some roles
   */
  function restricted(auth?: string[]) {
    if(user.organization) {
      return user.organization.role !== 'Member'
    }
  }

  return (
    <div id="panel">
      <div className="logo">
        <img alt={'Logo'} src={Logo} />
      </div>
      <div className="navigation">
        <ul className="menu">
          {app.navigation.map((item: ItemTypes, index:number) => {

            if(restricted(item.auth)) {
              // item.group = item?.group?.filter((sub: ItemTypes) => restricted(sub.auth))

              return (
                <li key={index} className={isActive(item.name)}>
                  {item.group ? (
                    <React.Fragment>

                      <Link to={`/${makepath([item.name, item.group[0]?.name])}`}>
                        <i className={item.icon}/>
                        <span>{item.name}</span>
                      </Link>

                      {item.name.toLowerCase() === pathname[1] && (
                        <ul>
                          {item.group.map((sub:ItemTypes, key:number) => (
                            <li
                              key={key}
                              className={
                                isActive(sub?.name)
                              }>
                              <Link to={`/${makepath([item.name, sub?.name])}`}>{sub?.name}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </React.Fragment>
                  ):(
                    <Link to={`/${makepath([item.name])}`}>
                      <i className={item.icon}/>
                      <span>{item.name}</span>
                    </Link>
                  )}
                </li>
              )
            }
            return null
          })}
          <li
            onClick={() => {
              dispatch(userActions.logout())
            }}>
            <Link to="/">
              <i className="icon-lock"/>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
})