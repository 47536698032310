import string from '@helpers/string'


/**
 * Storage
 */
const storage = {
  /**
   * Get data from browser's local storage
   * @returns {mixed}
   */
  get(key: string, de: boolean = false): any {
    var item = localStorage.getItem(key)
    if(de) {
      return string.decode(item as never)
    }
    return item
  },

  /**
   * Set value to local storage
   * @returns {void}
   */
  set(key: string, value: any): void {
    localStorage.setItem(key, value)
  },

  /**
   * Add value to existing array
   * @returns {void}
   */
  add(key: string, value: any): void {
    try {
      /**
       * If there's existing array value
       * then add it to the list
       */
      var item: any = localStorage.getItem(key)
      var data: any = JSON.parse(item)
      
      if(data) {
        if(Array.isArray(data)) {
          value.id = (data?.length ?? 0)+1
          data.push(value)
        }
        else {
          data = {...data, ...value}
        }
      }
      else {
        data = [value]
      }

      localStorage.setItem(key, JSON.stringify(data))
    }
    catch(e) {}
  },

  /**
   * Get data from browser's local storage and parse
   * @returns {object}
   */
  parse(key: string, de: boolean = false) {
    try {
      var item: any = localStorage.getItem(key)
      if(de) {
        item = string.decode(item as never)
      }
      return JSON.parse(item as never)
    }
    catch(e) {}
  },

  /**
   * Remove all data in the storage
   * @returns {void}
   */
  clear(): void {
    localStorage.clear()
  },

  /**
   * Remove existing value
   * @returns {void}
   */
  remove(key: string): void {
    localStorage.removeItem(key)
  }
}

export default storage

