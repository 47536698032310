/**
 * Helpers
 */
import {pure} from '@react/derive'

/**
 * Assets
 */
import Logo from '@assets/images/logo-orange.png'


/**
 * Login Screen
 */
export default pure(() => {
  return (
    <header id="header">
      <img id="logo" src={Logo} alt="logo" />
      <span>|  A safe social media platform</span>
    </header>
  )
})