import React from 'react'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'

/**
 * Components
 */
import Button from '@components/button'
import Editor from '@components/editor'

/**
 * Template Settings Screen
 */
export default pure(() => {
  const state = useState({
    saving: false,
    inviteSchools: {
      title: '',
      content: '',
    },
    inviteParents: {
      title: '',
      content: '',
    },
  })

  React.useEffect(() => {
    http.get('/settings', {
      query: {
        fields: [
          'inviteSchools',
          'inviteParents',
        ]
      }
    })
    .then(({status, result}:any) => {
      if(status === 200 && result) {
        state.set(result)
      }
    })
    .catch(console.log)
  }, [])

  
  /**
   * Save settings
   */
  function save() {
    state.set({
      saving: true
    })

    http.put('/settings', {
      data: {
        inviteSchools: state.inviteSchools,
        inviteParents: state.inviteParents
      }
    })
    .then(({status, result}:any) => {
      if(status === 200) {
        if(result) {
          setTimeout(() => {
            state.set({saving: false, ...result})
          }, 1000)
        }
      }
    })
    .catch(console.log)
  }


  /**
   * Handle change
   */
  function change(name: string, data: object) {
    state.set((state: any) => {
      return {
        [name]: {...state[name], ...data}
      }
    })
  }

  return (
    <div className="box">
      <header className="line">
        <h3>Email Invitation</h3>
      </header>

      <div
        style={{
          width: '50%'
        }}>
        <fieldset className="fields">
          <h4>Invite Schools</h4>
          <p>An email invitation template to send to school administrator.</p>
          
          <div className="field">
            <label>Title</label>
            <input
              type="text"
              value={state.inviteSchools.title}
              onChange={(e) => {
                change('inviteSchools', {title: e.target.value})
              }}
            />
          </div>
          <div className="field">
            <label>Content</label>
            <div className="editor">
              {state.inviteSchools.content && (
                <Editor
                  content={state.inviteSchools.content}
                  onChange={({content}: {content: string}) => {
                    change('inviteSchools', {content})
                  }}
                />
              )}
            </div>
          </div>
        </fieldset>

        <fieldset className="fields">
          <h4>Invite Parents</h4>
          <p>An email invitation template to send to parents to register.</p>
          <div className="field">
            <label>Title</label>
            <input
              type="text"
              value={state.inviteParents.title}
              onChange={(e) => {
                change('inviteParents', {title: e.target.value})
              }}
            />
          </div>
          <div className="field">
            <label>Content</label>
            <div className="editor">
              {state.inviteParents.content && (
                <Editor
                  content={state.inviteParents.content}
                  onChange={({content}: {content: string}) => {
                    change('inviteParents', {content})
                  }}
                />
              )}
            </div>
          </div>
        </fieldset>
      </div>
      <Button
        label={'Save'}
        style={{
          marginTop: 30
        }}
        preload={state.saving}
        onClick={save}
        className={'button-2'}
      />
    </div>
  )
})