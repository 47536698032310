/*
  eslint-disable react-hooks/exhaustive-deps
*/
import React from 'react'


/**
 * Hooks
 */
import {useState} from '@react/hooks'


/**
 * Types
 */
type PaginationProps = {
  total:number,
  limit:number,
  onPrev?: (page:string|number) => void
  onNext?: (page:string|number) => void
  onClick?: (page:string|number, current:string|number) => void
}



function Pagination({limit, total, onPrev, onNext, onClick}: PaginationProps) {
  const state = useState({
    current: 1,
    isLast: false,
    items: []
  })
  const pages: number = Math.ceil(total/limit)

  /**
   * Initial
   */
  React.useEffect(() => {
    if(pages) {
      if(pages <= 3) {
        const items: any[] = Array.from({
          length: pages
        })
        state.set({
          items: items.map((v, i) => i+1)
        })
      }
      else {
        state.set({items: [1,2,'...', pages]})
      }
    }
  }, [total, pages])


  function prev(_prev:number) {
    state.set(({isLast, prev}:any) => {
      if(isLast) {
        return {current: prev, isLast: false}
      }
      if(_prev > 3) {
        return {
          current: _prev,
          items: [_prev-1,_prev,'...', pages]
        } 
      }
      return {current: _prev}
    })
  }
  

  function next(current:number) {
    state.set(({current: prev, items}: any) => {
      if(current <= pages) {
        /**
         * Last
         */
        if(pages === current) {
          return {
            current,
            items: pages <= 3 ? items : [prev-1,prev,current]
          }
        }
        /**
         * Second to the last
         */
        if((pages-1) === current) {
          return {
            current,
            items: [prev,current,pages]
          }
        }
        return {current, items: [prev,current,'...', pages]}
      }
    })
  }

  
  return (
    <React.Fragment>
      <span
        className={state.current > 1 ? 'prev' : 'gray'}
        onClick={() => {
          if(state.current > 1) {
            if(onPrev) {
              onPrev(state.current-1)
            }
            prev(state.current-1)
          }
        }}>
        Previous
      </span>

      {state.items.map((page:string|number, key:number) => {
        return (
          <React.Fragment key={key}>
            <span
              className={state.current === page ? 'active' : ''}
              onClick={() => {
                if(state.current !== page) {
                  if(onClick) {
                    onClick(page, state.current)
                  }
                  state.set(({current}:any) => {
                    return {
                      prev: current,
                      current: page,
                      isLast: pages === page
                    }
                  })
                }
              }}>
              {page}
            </span>
          </React.Fragment>
        )
      })}

      <span
        className={pages > 1 && state.current !== pages ? 'next' : 'gray'}
        onClick={() => {
          if(state.current < pages) {
            if(onNext) {
              onNext(state.current+1)
            }
            next(state.current+1)
          }
        }}>
        Next
      </span>
    </React.Fragment>
  )
}

export default Pagination