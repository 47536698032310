import React from 'react'
import {RootState} from '@redux/store'
import {useSelector, useDispatch} from '@react/hooks'

import events from '../lib/events'

/**
 * Pure component
 */
export function pure(Component: any) {
  const Memoized = React.memo(Component)

  return ((props: any) => {
    const dispatch = useDispatch()

    const app = useSelector((state: RootState) => state.app)
    const user = useSelector((state: RootState) => state.user)

    return (
      <Memoized
        {...props}
        events={events}
        user={user.detail}
        store={{
          get(name: string) {
            if(app[name]) {
              return app[name]
            }
          },
          add(name: string) {
            if(app[name]) {
              dispatch({[name]: app[name]})
            }
          },
          dispatch(data:any) {
            for(var name of Object.keys(data)) {
              events.emit(name, data)
            }
            return dispatch(data)
          },
          state: app,
          status: user.status,
        }}
        modal={{
          data() {
            return app.modal.data
          },
          open(data: any) {
            dispatch({modal: data})
          },
          close() {
            dispatch({modal: null})
          }
        }}
        logged={user.logged}
      />
    )
  })
}